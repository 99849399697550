.FirstTH > th {
  padding: 0.5rem;
  /* width: 18rem; */
  border: 1px gray solid;
}
td {
  border: 1px gray solid;
  padding: 1%;
}

td > input {
  width: 50%;
  margin: 1%;
  border: 1px solid gray;
}

.datepicker {
  width: 133px !important;
}
