@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

hr {
  border: none;
  height: 2px;
  background: black;
}

.buttonWidth100 {
  min-width: 100px;
  max-width: 150px;
}

input[type="checkbox"] {
  transform: scale(1.8);
}

/* LoadingSpinner.js */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-spinner img {
  width: 150px; /* Adjust as needed */
  height: 150px; /* Adjust as needed */
}

.react-datepicker-popper {
  z-index: 2 !important;
}
/* .react-datepicker__input-container {
  border: 1px solid rgba(0, 0, 0, 0.61) !important;
} */
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: -11px !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 4px !important;
}
