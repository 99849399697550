.profile-pic {
  color: transparent;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.3s ease;
}
.profile-pic input {
  display: none;
}
.profile-pic img {
  position: absolute;
  object-fit: cover;
  width: 165px;
  height: 165px;
  box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
  border-radius: 100px;
  border: 1px solid gray;
  z-index: 0;
}
.profile-pic .-label {
  cursor: pointer;
  height: 165px;
  width: 165px;
}
.profile-pic:hover .-label {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;
  color: #fafafa;
  transition: background-color 0.2s ease-in-out;
  border-radius: 100px;
  margin-bottom: 0;
}
.profile-pic span {
  display: inline-flex;
  padding: 0.2em;
  height: 2em;
}
/* body {
  height: 100vh;
  background-color: #191815;
  display: flex;
  justify-content: center;
  align-items: center;
} */
body a:hover {
  text-decoration: none;
}

/* *************************************** */

/* projects tab */

.parent {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.child {
  width: 100%;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  /* background-image: url(/public/images/back.webp); */
  text-align: center;
}

.child:hover {
  transform: scale(1.1);
  color: #fafafa;
  backdrop-filter: #dd2727d0;
}

.child span {
  visibility: hidden;
}
.child:hover span {
  visibility: visible;
}

.formNewProfile .row {
  align-items: baseline;
  justify-content: center;
  margin-bottom: 2%;
}
.formNewProfile .row .col-sm-2 {
  padding-left: 4%;
}
