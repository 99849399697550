.modaloverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.modaldisplay {
  background-color: white;
  border-radius: 25px;
  padding: 2%;
  position: relative;
  width: 100%;
  max-width: 400px;
}
/* .react-datepicker__input-container {
  border: 1px solid rgba(0, 0, 0, 0.61) !important;
} */

/* create a container */

.centered-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.MuiInputBase-root {
  height: 100%;
}
.MuiFormControl-root {
  height: 100%;
}
