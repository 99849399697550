.select {
  padding: 1%;
  margin: 2%;
  background-color: white;
  border: 1px solid rgba(167, 167, 167, 0.678);
  border-radius: 5px;
  background-image: linear-gradient(45deg, transparent 50%, white 50%),
    linear-gradient(135deg, white 50%, transparent 50%),
    linear-gradient(to right, #0d6efd, #0d6efd);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  appearance: none;
  width: 100%;
}
.option {
  padding: 2%;
}
option:hover {
  background-color: rgba(207, 204, 204, 0.37);
}
